


























































































































import { RouterNames } from "@/router/routernames";
import { API } from "@/shared/api";
import { ssmHttpService } from "@/shared/services/http-service";
import { MessageService } from "@/shared/services/message-service";
import agrupacion_importacionesDtoModule from "@/store/modules/agrupacion_importacionesDto-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class importacion_excel extends Vue {
  public e1 = 1;
  public fichero!: File;
  public id_agrupacion!: number;

  public created() {
    this.$validator.errors.items.length;
    agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos();
  }

  public get agrupaciones() {
    return agrupacion_importacionesDtoModule.agrupacion_importacionesDtos;
  }

  public siguiente_paso(index: number) {
    this.e1 = index;
  }

  submitFile() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        /*Initialize the form data*/
        let formData = new FormData();

        /*Add the form data we need to submit*/
        formData.append("file", this.fichero);
        formData.append("agrupacion", this.id_agrupacion.toString());

        /*Make the request to the POST /single-file URL*/
        ssmHttpService
          .postfile(API.ExcelImport + "/excel", formData)
          .then(() => {
            MessageService.alertRespose(
              "Todo listo e importandose",
              "La importación estará lista en unos minutos",
              (res) => {
                this.$router.push({ name: RouterNames.Home });
              }
            );
          })
          .catch(function() {});
      } else {
        this.e1 = 1;
      }
    });
  }
}
