import { store } from "@/store/store";
import { agrupacion_importacionesDto } from "@/shared/dtos/agrupacion_importacionesDto";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "agrupacion_importacionesDtoModule",
  store,
  dynamic: true,
})
class agrupacion_importacionesDtoModule extends VuexModule {
  public agrupacion_importacionesDtos: agrupacion_importacionesDto[] = [];
  public agrupacion_importacionesDto: agrupacion_importacionesDto = new agrupacion_importacionesDto();

  @Action({ commit: "onGetagrupacion_importacionesDtos" })
  public async getagrupacion_importacionesDtos() {
    return await ssmHttpService.get(API.agrupacion_importacionesDto);
  }

  @Action({ commit: "onGetagrupacion_importacionesDto" })
  public async getagrupacion_importacionesDto(id: any) {
    return await ssmHttpService.get(API.agrupacion_importacionesDto + "/" + id);
  }

  @Action
  public async guardaragrupacion_importacionesDto(
    agrupacion_importacionesDto: agrupacion_importacionesDto
  ) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.agrupacion_importacionesDto,
      agrupacion_importacionesDto.toJson()
    );
  }

  @Action
  public async modificaragrupacion_importacionesDto(
    agrupacion_importacionesDto: agrupacion_importacionesDto
  ) {
    return await ssmHttpService.put(
      API.agrupacion_importacionesDto + "/" + agrupacion_importacionesDto.id,
      agrupacion_importacionesDto
    );
  }

  @Action
  public async eliminaragrupacion_importacionesDto(
    agrupacion_importacionesDto: agrupacion_importacionesDto
  ) {
    return await ssmHttpService.delete(
      API.agrupacion_importacionesDto + "/" + agrupacion_importacionesDto.id,
      null,
      false
    );
  }

  @Mutation
  public onGetagrupacion_importacionesDtos(res: agrupacion_importacionesDto[]) {
    this.agrupacion_importacionesDtos = res
      ? res.map((x) => new agrupacion_importacionesDto(x))
      : [];
  }

  @Mutation
  public onGetagrupacion_importacionesDto(res: agrupacion_importacionesDto) {
    this.agrupacion_importacionesDto = new agrupacion_importacionesDto(res);
  }
}
export default getModule(agrupacion_importacionesDtoModule);
